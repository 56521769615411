import React,{ useState, useEffect, useRef} from "react";
import { Row,Col,Button} from "react-bootstrap";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";
import Http from '../libs/http'
import Constants from '../libs/constants'
import Home from '../components/Home/Home'
import Tiendas from '../components/Tiendas/Tiendas'
import DetalleProductoMain from '../components/Categorias/DetalleProductoMain'
import Categorias from '../components/Categorias/Categoria'
import MenuCategorias from '../components/MenuCategorias'
import Menu from '../header/menu'
import OutsideClickHandler from 'react-outside-click-handler';
import Acerca from "./Acerca/Acerca";
import Blog from "./Blog/Blog";
import BlogDetalle from "./Blog/Detalle";
import Contactenos from "./Contactenos/Contactenos";
import MiCuenta from "./MiCuenta/MiCuenta";
import { Spinner } from "react-activity";
import $ from 'jquery'; 
import { NavLink } from "react-router-dom";
import DetalleOrden from "./DetalleOrden/DetalleOrden";
import OrdenFinalizada from "./DetalleOrden/OrdenFinalizada";
import Moment from 'moment';
import 'moment/min/locales'
import TrackPedido from "./DetalleOrden/TrackPedido";
import validator from 'validator'
import Club from "./Club/Club";
import Modal from 'react-modal';
import TerminosCondiciones from "./Terminos/TerminosCondiciones";
import TerminosCondicionesLlaollao from "./Terminos/TerminosCondicionesLlaollao";
import PoliticaPrivacidad from "./Terminos/PoliticaPrivacidad";
import TerminosCondicionesRifa from "./TerminosRifa/TerminosCondiciones";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DetallePromocionMain from "./Categorias/DetallePromocionMain";
import ViajeEspana from "./ViajeEspana/ViajeEspana";

const FB = window.FB;
const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "300px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

var  agregarSugerido = false

class HomeMain extends React.Component {

    state = {
        loading: false,
        dataHome:{amount_min:"",hours:[],status:""},
        showLoginModal:false,
        dataFooter: "",
        isMobileSend: false,
        cantidadProductos: 0,
        carrito: [],
        customer:{id:-1},
        total:0,
        ocultarEnvio:true,
        email:"",
        loadLogin:false,
        mensajeLogin:"",
        dataSugerido:[],
        cantidadSugerido:1,
        setIsOpenRegistro:false,
        setIsOpenLogin: false,
        setIsOpenPass: false,
        emailPass: "",
        loadPass: false,
        errorPassSend:false,
        successPassSend:false,
        activeTrack: false,
        activeBeneficio: false,
        beneficioAgregado:{idCode:0},
        blog_url:"",
        setIsOpenValidacionTienda: false,
        mensajeTiendaCerrada: "tienda cerrada",
        dataToppings:[]
    };

    handleClickMenu(e) {
        this.setState({showLoginModal:!this.state.showLoginModal})
    }

    handleClickMenuHide() {
        this.setState({showLoginModal:false})
    }

    handleClickDetalleOrden(e) {
        //self.location= "/detalleorden"
        //this.context.router.transitionTo("/detalleorden";
        if(this.state.dataHome.status != "active" ){
            $("#closeMenu").click()
            this.setState({setIsOpenValidacionTienda:true})
        }else if(this.state.customer.id == 0){
            $("#closeMenu").click()
            this.setState({setIsOpenRegistro:true})
        }else if(this.state.dataHome.amount_min <= this.state.total){
            window.location.replace("/detalleorden");
        }
        
    }

    handleLoginRequerido(e){
        this.setState({setIsOpenRegistro:true})
    }

    loadFbLoginApi() {
        /*var chatbox = document.getElementById('fb-customer-chat');
        chatbox.setAttribute("page_id", "361181347380578");
        chatbox.setAttribute("attribution", "biz_inbox");*/


        window.fbAsyncInit = function() {
            window.FB.init({
              xfbml            : true,
              version          : 'v12.0'
            });
          };
    
          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));

        console.log("Loading fb api");
    }

    removerProducto(e,item) {

        const key = `carrito-${item.keyProduct}`
        console.log(key)

        localStorage.removeItem(key)
        this.handleActualizarCarrito()
    }

    async removerBeneficio(e) {

        const key = `descuento`

        localStorage.removeItem(key)
        this.setState({beneficioAgregado:{idCode:0}}, () => {
            this.handleActualizarCarrito()
        });

        var dataUser = {}
        dataUser.customer = this.state.customer.id
        dataUser.user = this.state.customer.userId
        dataUser.location = Constants.instance.idDireccion()

        const resBeneficio = await Http.instance.post("rest/main/info-beneficios",JSON.stringify(dataUser));
        if(typeof resBeneficio.errorHttp === 'undefined'){
            if(resBeneficio.status == 200){
                this.setState({activeBeneficio:true,beneficio:resBeneficio}, () => {
                    try {
                        this.child.current.handleCarrito()
                    } catch (error) {
                        console.log(error);
                    }
                });
            }else{
                this.setState({activeBeneficio:false}, () => {
                    try {
                        this.child.current.handleCarrito()
                    } catch (error) {
                        console.log(error);
                    }
                });
            }

            this.handleActualizarCarrito()
        }

    }

    actualizarCantidad(e,item,numero){

        console.log("item",item)
        
        var nuevaCantidad = numero

        if(numero != 0 && numero < 26){
            if(item.promo == true){
                var totalProductoUno = (item.productoUno.precio_unitario + item.productoUno.precioExtra)
                var totalProductoDos = (item.productoDos.precio_unitario + item.productoDos.precioExtra)
                item.quantity = nuevaCantidad
                item.total = (totalProductoUno + totalProductoDos) * nuevaCantidad
    
                var carrito = JSON.stringify(item)
                const key = `carrito-${item.keyProduct}`
    
                localStorage.setItem(key,carrito)
            }else{
                var totalProducto = (item.precio_unitario + item.precioExtra) * nuevaCantidad
                item.quantity = nuevaCantidad
                item.total = totalProducto
    
                var carrito = JSON.stringify(item)
                const key = `carrito-${item.keyProduct}`
    
                localStorage.setItem(key,carrito)
            }
            
            this.handleActualizarCarrito()
        }


    }

    actualizarCantidadSugerido(e,position,numero){
        var nuevaCantidad = numero

        if(numero != 0 && numero < 26){
            var dataSugeridoClone = this.state.dataSugerido
            dataSugeridoClone[position].cantidad = nuevaCantidad
           this.setState({dataSugerido:dataSugeridoClone})
        }


    }
    

    async handleActualizarCarrito() {
        
        try {
            //const allKeys = { ...localStorage };
            var query = "carrito-"
            var totalCalcular = 0
            
            var i, results = [];
            var cantidadProductos = 0
            for (i in localStorage) {
                if (localStorage.hasOwnProperty(i)) {
                    if (i.match(query) || (!query && typeof i === 'string')) {
                        var value = JSON.parse(localStorage.getItem(i));
                        console.log("value ==",value)
                        if(value.promo == true){
                            var precioExtra = 0
                            var toppingsValidos = value.productoUno.custom.filter((item) => item.price !== 0)
                            if(value.productoUno.toppingsFree < toppingsValidos.length){
                                for (var j=value.productoUno.toppingsFree; j < toppingsValidos.length; j++) {
                                    precioExtra += toppingsValidos[j].price
                                } 
                            }

                            value.productoUno.precioExtra = precioExtra
                            value.productoUno.keyProduct = value.keyProduct

                            var precioExtraDos = 0
                            var toppingsValidosDos = value.productoDos.custom.filter((item) => item.price !== 0)
                            if(value.productoDos.toppingsFree < toppingsValidosDos.length){
                                for (var j=value.productoDos.toppingsFree; j < toppingsValidosDos.length; j++) {
                                    precioExtraDos += toppingsValidosDos[j].price
                                } 
                            }

                            value.productoDos.precioExtra = precioExtraDos
                            value.productoDos.keyProduct = value.keyProduct



                        }else{
                            var precioExtra = 0
                            var toppingsValidos = value.custom.filter((item) => item.price !== 0)
    
                            if(value.toppingsFree < toppingsValidos.length){
                                for (var j=value.toppingsFree; j < toppingsValidos.length; j++) {
                                    precioExtra += toppingsValidos[j].price
                                } 
                            }
                        }

                        if(typeof value.orden === 'undefined'){
                            value.orden = 0
                        }
                        
                        value.precioExtra = precioExtra
                        results.push(value);
                        totalCalcular += value.total
                        cantidadProductos += value.quantity
                    }
                }
            }


            const dataDescuento = localStorage.getItem("descuento");
            const initialDescuento = JSON.parse(dataDescuento);
            if(initialDescuento != null){
                this.setState({beneficioAgregado:initialDescuento})
            }
            
            results.sort((a, b) => (a.orden) - (b.orden));



            this.setState({cantidadProductos:cantidadProductos,carrito:results,total:totalCalcular})

            this.obtenerSugeridos()

            try {
                this.child.current.handleCarrito()
            } catch (error) {
                console.log(error);
            }

        } catch (error) {
            console.log(error);
        }
        
    }

    /*compareSecondColumn(a, b) {
        if (a[1] === b[1]) {
            return 0;
        }
        else {
            return (a[1] < b[1]) ? -1 : 1;
        }
    }*/
    
    handleAgregarProducto(e,item) {

        
        if(agregarSugerido == false){
            
            agregarSugerido = true

            var dataProductoSave = {}
            dataProductoSave.productId = item.id
            dataProductoSave.quantity = item.cantidad
            dataProductoSave.total = item.price * item.cantidad
            dataProductoSave.precio_unitario = item.price
            dataProductoSave.currency = item.currency
            dataProductoSave.base_image = item.small_image
            dataProductoSave.desc = item.desc
            dataProductoSave.small_desc = item.small_desc
            dataProductoSave.name = item.name_online
            dataProductoSave.isTopping = false
            dataProductoSave.promo = false
            dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))
            var custom = []
    
            dataProductoSave.custom = custom
            dataProductoSave.keyProduct =  `${dataProductoSave.productId}${(Math.floor(Math.random() * 100) + 1)}`
    
            const key = `carrito-${dataProductoSave.keyProduct}`
            var carrito = JSON.stringify(dataProductoSave)
    
            localStorage.setItem(key,carrito)
    
            this.handleActualizarCarrito()
    
            this.setState({cantidadSugerido:1})

        }
        

       

    }

    handleAgregarProductoTopings(e,item) {

        var dataProductoSave = {}
        dataProductoSave.productId = item.id
        dataProductoSave.isTopping = true
        dataProductoSave.promo = false
        
        dataProductoSave.quantity = 1
        dataProductoSave.total = item.price
        dataProductoSave.precio_unitario = item.price
        dataProductoSave.currency = item.currency
        dataProductoSave.base_image = item.icon_image
        dataProductoSave.desc = item.description
        dataProductoSave.small_desc = item.description
        dataProductoSave.name = item.name
        dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))
        
        var custom = []

        dataProductoSave.custom = custom
        dataProductoSave.keyProduct =  `${dataProductoSave.productId}${(Math.floor(Math.random() * 100) + 1)}`

        const key = `carrito-${dataProductoSave.keyProduct}`
        var carrito = JSON.stringify(dataProductoSave)

        localStorage.setItem(key,carrito)

        this.handleActualizarCarrito()
       

    }

    handleAplicarDescuento(e,item) {

        const key = `descuento`
        var descuento = JSON.stringify(item)

        localStorage.setItem(key,descuento)

        this.setState({beneficioAgregado:item,activeBeneficio:false}, () => {
            try {
                this.child.current.handleCarrito()
            } catch (error) {
                console.log(error);
            }
        });


    }

    constructor(props) {
        super(props);
        this.handleLoginRequerido = this.handleLoginRequerido.bind(this);
        this.handleActualizarCarrito = this.handleActualizarCarrito.bind(this);
        this.removerProducto = this.removerProducto.bind(this);
        this.actualizarCantidad = this.actualizarCantidad.bind(this);
        this.child = React.createRef();
    }

    loginUser = async (e) => {
        if(this.state.email == ""){
            this.setState({errorEmail:true,errorEmailText:"campo obligatorio."})
            return
        }else if(validator.isEmail(this.state.email) == false){
            this.setState({errorEmail:true,errorEmailText:"Email no valido"})
            return
        }

        if(this.state.pass == ""){
            this.setState({errorPass:true})
            return
        }

        this.setState({loadLogin:true})

        var dataUserSend = {}
        dataUserSend.token = ""
        dataUserSend.user = this.state.email
        dataUserSend.password = this.state.pass

        const res = await Http.instance.post("rest/account/login",JSON.stringify(dataUserSend));
        console.log("resss",res)
        if(res.errorCode == 0){
            localStorage.removeItem("direccionSelect")
            localStorage.setItem("customer", JSON.stringify(res.customer_info));
            window.location = "/";
        }else{
            this.setState({mensajeLogin:res.errorMessage,loadLogin:false})
        }

    }

    passUser = async (e) => {

        if(this.state.loadPass == false){
            if(this.state.emailPass == ""){
                this.setState({errorEmailPass:true,errorEmailPassText:"campo obligatorio."})
                return
            }else if(validator.isEmail(this.state.emailPass) == false){
                this.setState({errorEmailPass:true,errorEmailPassText:"Email no valido"})
                return
            }
    
            this.setState({loadPass:true,mensajePass:"",errorPassSend:false,successPassSend:false})
    
            var dataUserSend = {}
            dataUserSend.email = this.state.emailPass
    
            const res = await Http.instance.post("rest/account/recovery-password",JSON.stringify(dataUserSend));
            console.log("resss",res)


            if(typeof res.errorHttp == 'undefined'){
                console.log("resss!!",res.errorHttp)

                if(res.status != "success"){
                    this.setState({loadPass:false,mensajePass:res.message,errorPassSend:true,successPassSend:false,emailPass:""})
                }else{
                    this.setState({loadPass:false,mensajePass:res.message,errorPassSend:false,successPassSend:true,emailPass:""})
                }
                
            }
        }
        
    }

    async obtenerSugeridos(){

        try {
            var dataSendCarrito = {}
            dataSendCarrito.cart = []

            var query = "carrito-"
            
            var i, results = [];
            for (i in localStorage) {
                if (localStorage.hasOwnProperty(i)) {
                    if (i.match(query) || (!query && typeof i === 'string')) {
                        var item = JSON.parse(localStorage.getItem(i));
                        
                        var itemProducto = {}
                        itemProducto.quantity = item.quantity

                        if(item.promo == true){
                            itemProducto.custom = []
                            itemProducto.precio_unitario = item.total
                            itemProducto.productId = 0
                            itemProducto.total = item.total
                        }else{
                            itemProducto.custom = item.custom
                            itemProducto.precio_unitario = item.precio_unitario
                            itemProducto.productId = item.productId
                            itemProducto.total = item.total
                        }
                        
                        dataSendCarrito.cart.push(itemProducto)
                        

                    }
                }
            }

            console.log("dataSendCarrito",dataSendCarrito)

            const resSugerido = await Http.instance.post("rest/cart/sugeridos",JSON.stringify(dataSendCarrito));
            if(typeof resSugerido.errorHttp == 'undefined'){

                for (var i = 0; i < resSugerido.length; i+=1) {
                    resSugerido[i].cantidad = 1
                }
                this.setState({dataSugerido:resSugerido})
                agregarSugerido = false
            }

        } catch (error) {
            console.log(error);
            agregarSugerido = false
        }
    }

    dataProductoCarrito(item,showCantidad){
       
        return(
            <div className="contInfo">
                <div  className="nameProducto">{item.name} {item.currency}{item.precio_unitario.toFixed(2)} (c/u)</div>
                {
                    item.custom.length != 0 ?
                    <div>
                        <div className="toppings"><span>{item.toppingsFree} toppings</span></div>
                        <ul>
                            {item.custom.map((itemToppping, index) => (

                                    index < item.toppingsFree ? <li>{itemToppping.name}</li>
                                    : null

                                
                            ))}

                            
                        </ul>
                        
                        

                        {
                            item.toppingsFree < item.custom.length ?
                                <div>

                                    <div className="nameProducto">topping extra(s) {item.currency}{item.custom[0].price.toFixed(2)} (c/u)</div>
                                    <ul>
                                        {item.custom.map((itemToppping, index) => (

                                                index >= item.toppingsFree ? <li className={itemToppping.price == 0 ? "gratis" : ""}>{itemToppping.name} {itemToppping.price == 0 ? "(Gratis)": null}</li>
                                                : null

                                            
                                        ))}
                                    </ul>

                                </div>
                            : null
                        }
                    </div>
                    : null
                }

                <div>
                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginBottom:"15px"}}>
                        <div className="txTotal">Subtotal</div>
                        <div className="subTotalCarrito">{item.currency}{parseFloat(item.precio_unitario + item.precioExtra).toFixed(2)}</div>
                    </div>
                </div>
                <div className="clear"></div>
                
                <div className="contCantidad d-flex flex-row-reverse" style={{paddingRight:"17px",marginTop:"10px"}}>
                    {
                    showCantidad ?
                        <div className="contTotalProducto">{item.currency}{parseFloat(item.total).toFixed(2)}</div>
                        : null
                    }

                    {
                    showCantidad ?
                        <div className="contCantidad">
                            <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity-1)}>-</div>
                            <div className="txCantidad">{item.quantity}</div>
                            <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity+1)}>+</div>
                        </div>

                        : null
                    }

                    <div className='contEditAddress' onClick={(e) => this.removerProducto(e,item)}>
                        <i className="glyphicon glyphicon-trash"></i>
                    </div>
                </div>
            </div>
        )
    }

    async toppingsSugeridos(){
        var idUser = 0
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){            
            idUser = initialCustomer.id
        }

        var dataUserSend = {}
        dataUserSend.customer = idUser
        const resToppings =  await Http.instance.post("rest/product/toppings",JSON.stringify(dataUserSend));
        if(typeof resToppings.errorHttp == 'undefined'){
            console.log("resToppings",resToppings)

            this.setState({dataToppings:resToppings})
            
        }

    }

    async infoGeneral(){
        var idUser = 0
        const customer = localStorage.getItem("customer");

        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){            
            idUser = initialCustomer.id
        }

        var dataUserSend = {}
        dataUserSend.customer = idUser

        const resDelivery =  await Http.instance.post("rest/main/delivery-info",JSON.stringify(dataUserSend));
        console.log("resDelivery ==",resDelivery)
        if(typeof resDelivery.errorHttp == 'undefined'){

            this.setState({dataHome:resDelivery,blog_url:resDelivery.blog_url,mensajeTiendaCerrada:resDelivery.status_message})

            //aplicar descuento automaticamente
            const dataDescuento = localStorage.getItem("descuento");
            const initialDescuento = JSON.parse(dataDescuento);
            if(initialDescuento == null && initialCustomer != null && resDelivery.info_descuento != null){
                this.handleAplicarDescuento(null,resDelivery.info_descuento)
            }
            
        }
    }

    cerraSesion  = async (e) => {
        var i;
        for (i in localStorage) {
            if (localStorage.hasOwnProperty(i)) {
                localStorage.removeItem(i)
            }
        }

        window.location.replace("/");
    }

    async componentDidMount() {
        Moment.locale('es');

        this.loadFbLoginApi();

        this.handleActualizarCarrito()
        var idUser = 0

        //sesion iniciada
        const customer = localStorage.getItem("customer");

        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            this.setState({customer:initialCustomer})
            
            idUser = initialCustomer.id

            var dataUser = {}
            dataUser.customer = initialCustomer.id
            dataUser.user = initialCustomer.userId

            
            const resTrack = await Http.instance.post("rest/cart/order-track",JSON.stringify(dataUser));
            if(typeof resTrack.errorHttp === 'undefined'){
                if(resTrack.status == "error"){
                    this.setState({activeTrack:false})
                }else{
                    this.setState({activeTrack:true})
                }
            }

            //if(this.state.beneficioAgregado.idCode == 0){
                const resBeneficio = await Http.instance.post("rest/main/info-beneficios",JSON.stringify(dataUser));
                if(typeof resBeneficio.errorHttp === 'undefined'){

                    if(resBeneficio.status == 200){

                        if(this.state.beneficioAgregado.idCode == 0){
                            this.setState({activeBeneficio:true,beneficio:resBeneficio})
                        }else{
                            if(this.state.beneficioAgregado.idCode != resBeneficio.idCode){
                                //remover beneficio
                                const key = `descuento`
                                localStorage.removeItem(key)

                            }
                        }
                    }if(resBeneficio.status == 100){

                       //remover beneficio
                       const key = `descuento`
                       localStorage.removeItem(key)
                       this.setState({beneficioAgregado:{idCode:0}}, () => {
                        this.handleActualizarCarrito()
                    });

                    }else{
                        this.setState({activeBeneficio:false})
                    }
                    
                    
                }
            //}

            
            

        }else{
            this.setState({customer:{id:0}})
        }

        console.log("initialCustomer",initialCustomer)

        var  shadow_layer = $("#cd-shadow-layer"),
        lateral_cart = $("#cd-cart"),
        menu_navigation = $(".top-nav-list"),
        close_menu = $("#closeMenu"),
        cont_carrito = $(".contCarrito")

        lateral_cart.on("click", function() {
            
        })


        shadow_layer.on("click", function() {
            shadow_layer.removeClass("is-visible")
            if(lateral_cart.hasClass("speed-in")){
                lateral_cart.removeClass("speed-in").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                   // $("body").removeClass("overflow-hidden")
                })
                menu_navigation.removeClass("speed-in")
            }else{
                menu_navigation.removeClass("speed-in").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                   // $("body").removeClass("overflow-hidden")
                })
                lateral_cart.removeClass("speed-in")
            }
        })

        close_menu.on("click", function() {
            shadow_layer.removeClass("is-visible")
            if(lateral_cart.hasClass("speed-in")){
                lateral_cart.removeClass("speed-in").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                    //$("body").removeClass("overflow-hidden")
                })
                menu_navigation.removeClass("speed-in")
            }else{
                menu_navigation.removeClass("speed-in").on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                   // $("body").removeClass("overflow-hidden")
                })
                lateral_cart.removeClass("speed-in")
            }
        })

        cont_carrito.on("click", function() {
            menu_navigation.removeClass("speed-in")


            if(lateral_cart.hasClass("speed-in")){
                lateral_cart.removeClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                    //$("body").removeClass("overflow-hidden")
                })
                shadow_layer.removeClass("is-visible")
             }else{
                lateral_cart.addClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                    //$("body").addClass("overflow-hidden")
                })
                shadow_layer.addClass("is-visible")
            }
        })


       

        this.infoGeneral()
        this.toppingsSugeridos()

        const resFooter =  await Http.instance.get("rest/pages/footer");
        if(typeof resFooter.errorHttp == 'undefined'){
            this.setState({dataFooter:resFooter.content})
        }


        

        
    }


    render() {

      return (
        <div>
            
           <Row>

                <Col xs={2}>
                <div className="sticky-md-top">
                    <a href="/" title="llaollao">
                    <img className="logo-image" src="/images/logo_llaollao.png" alt="llaollao" />
                    </a>

                    <div className="menuLateral">
                    <MenuCategorias/>

                    {
                        Constants.instance.onlyCatalog == false ?
                            <div className="contInfoDelivery">
                                {
                                    this.state.dataHome.amount_min != "" ? <div className="espacioInfoDelivery"> <img className="imgDelivery" src="/images/food_delivery.png" alt="llaollao" /> Pedido mínimo de producto: {this.state.dataHome.currency}{parseFloat(this.state.dataHome.amount_min).toFixed(2)}</div> : ""
                                }
                                {this.state.dataHome.hours.map(item => (
                                    <div className="espacioInfoDelivery"> <img className="imgHorarios" src="/images/reloj_pared.png" alt="llaollao" /> 
                                    <span className="tituloHorarios">Horario Delivery</span>
                                    <span className="horariosSpan">{item.day}<br/>{item.hour}</span></div>
                                ))}
                            </div>
                        : null
                    }
                    

                    </div>

                
                </div>
                </Col>
                <Col xs={10}>
                <Row className="header">
                    <Col xs={8} className="columnaDos">
                    <Menu blog_url={this.state.blog_url} />        
                    </Col>
                    <Col xs={4} className="columnaTres">
                    <Row>
                    
                        
                        <Col xs={5} >
                            <NavLink  to={"/llaollaoclub"}  title="llaollao" className="linkIniciar d-flex justify-content-center">
                                <img className="logo-image-club" src="/images/club.png" alt="llaollao" />
                            </NavLink>
                        </Col>
                        <Col xs={Constants.instance.onlyCatalog() == false ? 5 :7} className="nopadding">

                            {
                                this.state.customer.id == 0 ?
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            this.handleClickMenuHide()
                                        }}
                                        >
                                        <div className="contIniciaSesion d-flex justify-content-center" ref={this.textinput}>
                                            <div onClick={(e) => this.handleClickMenu(e)}>
                                                <div data-toggle="collapse" aria-hidden="false"><span className="iniciarSesion">Inicia sesión</span><br/>o Regístrate 
                                                    <i className="glyphicon glyphicon-chevron-down linkMore"></i>
                                                </div>
                                            </div>

                                            <div id="popup-login" className={"popup-header popup--anchor-right"} style={this.state.showLoginModal?{display:"block"}:{display:'none'}}>
                                                <div id="header-account-sign-in-form" >
                                                    <input type="hidden" name="target" id="target" value="" />
                                                    <div className="input">
                                                        <label for="header-account-sign-in-email">EMAIL</label>
                                                        <input 
                                                        type="email"
                                                        className={this.state.errorEmail ? "error" : ""} 
                                                        onChange={(text) => {
                                                            this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                                        }}
                                                        value={this.state.email}
                                                        name="user"/>

                                                        {
                                                            this.state.errorEmail == true ?
                                                                <label className="errorLogin">{this.state.errorEmailText}.</label>
                                                            :null
                                                        }

                                                    </div>
                                                    <div className="input">
                                                        <label for="header-account-sign-in-password">CONTRASEÑA
                                                            <a onClick={(e) => this.setState({setIsOpenPass:true}) } className="button-secondary forgot-password-link">Restablecer contraseña</a>
                                                        </label>
                                                        <input 
                                                        type="password" 
                                                        className={this.state.errorPass ? "error" : ""} 
                                                        onChange={(text) => {
                                                            this.setState({pass:text.target.value,errorPass:false})
                                                        }}
                                                        value={this.state.pass}
                                                        autocomplete="off" 
                                                        name="pass" 
                                                         />

                                                        {
                                                            this.state.errorPass == true ?
                                                                <label className="errorLogin">Campo obligatorio.</label>
                                                            :null
                                                        }

                                                    </div>

                                                    {
                                                        this.state.mensajeLogin != "" ?
                                                            <label className="errorLogin" style={{textAlign:"center",display:"block",paddingBottom:"0px"}}>{this.state.mensajeLogin}</label>
                                                        :null
                                                    }


                                                    
                                                    
                                                    <div className="input">

                                                        <Button onClick={(e) => this.loginUser(e)} className="btn-agregar btn btn-primary btn-agregar btnConfirmar" style={{marginRight:"20px",height:"34px",marginLeft:"20px",width: 'calc(100% - 40px)',marginTop:"10px",marginBottom:"20px"}}>
                                                        {
                                                            this.state.loadLogin == true ?
                                                                <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                            : 
                                                            "INGRESAR A CUENTA"
                                                        }
                                                        </Button>

                                                        
                                                    </div>
                                                    

                                                    <div className="popup-footer">
                                                        <p>¿No tienes cuenta?,  <a href="/crearcuenta" title="Registrate">Regístrate</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                        
                                    </OutsideClickHandler>

                                :
                                <OutsideClickHandler
                                        onOutsideClick={() => {
                                            this.handleClickMenuHide()
                                        }}
                                        >

                                        <div className="contIniciaSesion" ref={this.textinput}>
                                            <div >
                                                <div className="linkIniciar" onClick={(e) => this.handleClickMenu(e)} title="tu cuenta">
                                                    <span className="saludoUser">¡Hola! {this.state.customer.name}</span>tu cuenta 
                                                    <i className="glyphicon glyphicon-chevron-down linkMore"></i>
                                                </div>
                                            </div>

                                            <div id="popup-login" className={"popup-header popup--anchor-right modalCuenta"} style={this.state.showLoginModal?{display:"block"}:{display:'none'}}>
                                                <div id="header-account-sign-in-form" onClick={(e) => this.handleClickMenuHide(e)}>
                                                    <div className="headerTuCuenta">Tu Cuenta</div>
                                                    <NavLink className="linkIniciar" to={"/micuenta/datospersonales"}  title="tu cuenta">
                                                        Cuenta
                                                    </NavLink>
                                                    {
                                                        Constants.instance.onlyCatalog() == false ?
                                                            <NavLink className="linkIniciar" to={"/micuenta/direcciones"}  title="tu cuenta">
                                                                Direcciones
                                                            </NavLink>
                                                        : null
                                                    }
                                                    <NavLink className="linkIniciar" to={"/micuenta/historialordenes"}  title="tu cuenta">
                                                        Historial de ordenes
                                                    </NavLink>
                                                    <NavLink onClick={(e) => this.cerraSesion(e)} className="linkIniciar"  to={"#"} title="tu cuenta">
                                                        Cerrar Sesión 
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                        
                                </OutsideClickHandler>

                                    
                            }
                            

                           
                        </Col>
                        {
                            Constants.instance.onlyCatalog() == false ?
                                <Col xs={2} className="nopadding contCarrito">

                                                        

                                <img className="iconCarrito" src="/images/IcoCarretilla.png" alt="llaollao" />
                                {
                                    this.state.cantidadProductos > 0 ?
                                        <div className="cantidadProductos">{this.state.cantidadProductos}</div>
                                    : null
                                }

                                </Col>
                            : null
                        }
                        
                    </Row>
                    
                    
                    
                    <div></div>
                    </Col>
                </Row>
                
                   
                    <Switch>
                        <Route exact path="/">
                            <Home status={this.state.dataHome.status} activeTrack={this.state.activeTrack} />
                        </Route>
                        <Route exact path="/tiendas">
                            <Tiendas status={this.state.dataHome.status} activeTrack={this.state.activeTrack} />
                        </Route>

                        <Route exact path="/llaollaoclub">
                            <Club status={this.state.dataHome.status} loginRequerido={this.handleLoginRequerido} isMobileSend={this.props.isMobileSend} activeTrack={this.state.activeTrack} />
                        </Route>

                        <Route exact path="/nosotros">
                            <Acerca status={this.state.dataHome.status} isMobileSend={this.props.isMobileSend} activeTrack={this.state.activeTrack} />
                        </Route>

                        <Route exact path="/viajeaespaña">
                            <ViajeEspana status={this.state.dataHome.status} isMobileSend={this.props.isMobileSend} activeTrack={this.state.activeTrack} />
                        </Route>

                        <Route exact path="/terminosderifa">
                            <TerminosCondicionesRifa status={this.state.dataHome.status} isMobileSend={this.props.isMobileSend} activeTrack={this.state.activeTrack} />
                        </Route>
                        

                        <Route path="/contactenos/:tab" render={(props) => <Contactenos {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/contactenos" render={(props) => <Contactenos {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/terminoscondiciones" render={(props) => <TerminosCondiciones {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/politicasprivacidad" render={(props) => <PoliticaPrivacidad {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/terminoscondicionesllaollao" render={(props) => <TerminosCondicionesLlaollao {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>
                        
                        <Route path="/micuenta/:tab" render={(props) => <MiCuenta {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/micuenta" render={(props) => <MiCuenta {...props} activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

           
                        {
                            Constants.instance.onlyCatalog() == false ?
                                <>
                                    <Route path="/detalleorden" render={(props) => <DetalleOrden ref={this.child} {...props} show_tip={this.state.dataHome.show_tip}  activeTrack={this.state.activeTrack} amountMin={this.state.dataHome.amount_min} currency={this.state.dataHome.currency}  status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                                    </Route>
                                    <Route exact path="/ordenfinalizada">
                                        <OrdenFinalizada activeTrack={this.state.activeTrack} isMobileSend={this.props.isMobileSend} status={this.state.dataHome.status} />
                                    </Route>
                                    <Route exact path="/rastreopedido">
                                        <TrackPedido status={this.state.dataHome.status}  isMobileSend={this.props.isMobileSend} />
                                    </Route>
                                </>
                                
                            : null
                        }

                      
                        <Route path="/categoria/:id/producto/:id" render={(props) => <DetalleProductoMain {...props} activeTrack={this.state.activeTrack} status={this.state.dataHome.status} promotion_info={this.state.dataHome.promotion_info} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/categoria/promocion" render={(props) => <DetallePromocionMain {...props} theme={this.props.theme} activeTrack={this.state.activeTrack} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        <Route path="/categoria/:id" render={(props) => <Categorias {...props} activeTrack={this.state.activeTrack} promotion_info={this.state.dataHome.promotion_info} status={this.state.dataHome.status} actualizarCarrito={this.handleActualizarCarrito} />}>
                        </Route>

                        

                        <Route exact path="*">
                            <Home status={this.state.dataHome.status} activeTrack={this.state.activeTrack} />
                        </Route>
                    </Switch>

                
                

                <Row>
                    <Col xs={12}>
                    <div className="footer"  dangerouslySetInnerHTML={{__html: this.state.dataFooter}}></div>
                    </Col>
                </Row>
                </Col>

            </Row>

            <div id="cd-shadow-layer"></div>

            {
                Constants.instance.onlyCatalog() == false ?
                    <div id="cd-cart">
                    

                    <div className="headerMenu">
                        <div className="txNuevaOrden">Nueva Orden</div>
                        <div className="txFecha">{Moment(new Date()).format('dddd, LL')}</div>
                        <img onClick={this.closeModal} src="/images/cross.png" className="closeModal" id="closeMenu" />
                    </div>

                    <div className="contCarritoProducto">
                        {
                            this.state.carrito.length != 0 ?
                            <div>
                                <h2 className="txDetalle">DETALLE:</h2>
                                <hr />
                            </div>
                            
                        : null 
                        }
                    
                        {
                            this.state.ocultarEnvio == false ?
                                <div>
                                    <div className="contCostoMonto">
                                        <div className="txCosto">Costo de envío a zona seleccionada</div>
                                        <div className="txMonto">$2.00</div>
                                    </div>
                                    <div className="clear"></div>
                                    <hr />
                                </div>
                            : null
                        }
                    

                        

                        {this.state.carrito.map(item => (
                            <div>
                                <div className="contProducto">

                                {
                                    item.isTopping == true ?
                                        <div className="circleTopping" style={{width:"117px",height:"80px",marginRight:"15px",marginLeft:'20px'}}>
                                            <div className="contentTopping">
                                                <img className="imgTopping" src={item.base_image} />
                                            </div>
                                            
                                        </div>
                                    :
                                    null
                                }


                                {
                                    item.promo == false && item.isTopping == false  ?
                                        <img src={item.base_image} className="imgProducto" />
                                    :
                                        null
                                }


                                {
                                    item.promo == true ?
                                        <div>
                                            <div  className="namePromo">{item.name}</div>
                                            <div style={{display:"flex"}}>
                                                <img src={item.productoUno.base_image} className="imgProducto" />
                                                {this.dataProductoCarrito(item.productoUno,false)}
                                            </div>

                                            <div style={{display:"flex"}}>
                                                <img src={item.productoDos.base_image} className="imgProducto" />
                                                {this.dataProductoCarrito(item.productoDos,false)}
                                            </div>
                                            <div className="contInfo">
                                                <div className="clear"></div>
                                                <div className="contCantidad d-flex flex-row-reverse" style={{paddingRight:"17px",marginTop:"10px"}}>
                                                    <div className="contTotalProducto">{item.productoDos.currency}{parseFloat(item.total).toFixed(2)}</div>

                                                    <div className="contCantidad">
                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity-1)}>-</div>
                                                        <div className="txCantidad">{item.quantity}</div>
                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity+1)}>+</div>
                                                    </div>

                                                    <div className='contEditAddress' onClick={(e) => this.removerProducto(e,item)}>
                                                        <i className="glyphicon glyphicon-trash"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    null
                                }

                                {
                                    item.promo != true ?
                                    <div className={item.isTopping ? "contInfo" : ""}>
                                        {this.dataProductoCarrito(item,true)}
                                    </div>
                                    :
                                    null
                                }
                                    
                                    

                                    
                                </div>
                                <hr />
                            </div>
                        ))}

                                            
                        

                        {
                            this.state.carrito.length == 0 ?
                            <div>
                                <img className="noOrdenes" src="/images/empty_cart.svg" alt="No hay órdenes en tu carrito." />
                                <div className="noHayOrdenes">No hay órdenes en tu carrito.</div>
                            </div>
                            :
                            <div>
                                <div className="imagenesIlustrativa">* imagenes ilustrativas</div>
                                {this.state.dataSugerido.map((item, index) => (
                                    <div>
                                        <div className="contProducto contProductoBotella">
                                            <img src={item.small_image} className="imgProducto" />
                                            <div className="contInfo">
                                                <div  className="nameProducto">{item.name_online}</div>
                                                <div className="toppings">{item.desc}</div>
                                                <div className="contCantidad">
                                                    <div className="contCantidad">
                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidadSugerido(e,index,item.cantidad - 1)}>-</div>
                                                        <div className="txCantidad">{item.cantidad}</div>
                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidadSugerido(e,index,item.cantidad + 1)}>+</div>
                                                    </div>
                                                    <div className="contTotalProducto">{item.currency}{parseFloat(item.price * item.cantidad).toFixed(2)}</div>
                                                    
                                                </div>
                                                <Button style={{width:"95%",marginTop:"10px",marginLeft:"0px"}} onClick={(e) => this.handleAgregarProducto(e,item)} className="btn-agregar btnContinuarCrearCuenta">
                                                    {
                                                        this.state.loadingServicioCliente == true ?
                                                            <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                        : 
                                                        "Agregar"
                                                    }
                                                    </Button>
                                            </div>

                                            
                                        </div>
                                    </div>     
                                ))}

                                <div className="contToppings contToppingsCarrito" style={{minHeight:"100px"}}>
                                    <div className="agregaToppingsCarrito">Agrega toppings extras a tu orden</div>                       
                                    <Tabs onSelect={this.handleSelectTabs} style={{marginTop:"10px"}} className="tabsMovil">
                                        <TabList>
                                            {this.state.dataToppings.map((item, index) => (

                                                <Tab tabFor={index}>{item.name}</Tab>
                                            ))}
                                        </TabList>

                                        {this.state.dataToppings.map((item, index) => (
                                            <TabPanel tabId={index}>
                                                <div className="rowRegistro">

                                                    {item.items.map(itemChildren => (

                                                        
                                                        itemChildren.id != 35 ?
                                                            <Col xs={4} className="contMainItem" onClick={(e) => this.handleAgregarProductoTopings(e,itemChildren)}>
                                                                <div className="circleTopping">
                                                                    <div className="contentTopping">
                                                                        <div className="masMenos">+</div>
                                                                        <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="nameToppingD">{itemChildren.name}</div>
                                                            </Col>

                                                        :
                                                        null
                                                    ))}


                                                    {item.items.filter((item) => item.id == 35).map(itemChildren => (

                                                        <Col xs={4}  style={{marginTop:"10px"}} className="contMainItem contMascotaMovil" onClick={(e) => this.handleAgregarProductoTopings(e,itemChildren)}>
                                                            <div className="circleTopping"  style={{marginTop:"10px"}}>
                                                                <div className="contentTopping" >
                                                                    <div className="masMenos">+</div>
                                                                    <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="nameToppingD" style={{paddingBottom:"15px"}}>{itemChildren.name}</div>
                                                            <div className="titleMascotaMovil">Mascotas</div>
                                                        </Col>

                                                    ))}

                                                    

                                                </div>
                                                
                                            </TabPanel>
                                        ))}
                                    </Tabs>

                                </div>


                                {
                                    this.state.activeBeneficio ?
                                        <div>
                                            <div className="contProducto contProductoBotella">
                                                <img src="/images/promociones.png" className="imgBeneficio" />
                                                <div className="contInfo">
                                                    <div  className="nameProducto">{this.state.beneficio.title}</div>
                                                    <div className="toppings">{this.state.beneficio.description}</div>
                                                
                                                    <Button style={{width:"95%",marginTop:"10px",marginLeft:"0px"}} onClick={(e) => this.handleAplicarDescuento(e,this.state.beneficio)} className="btn-agregar btnContinuarCrearCuenta">
                                                        {
                                                            this.state.loadingServicioCliente == true ?
                                                                <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                            : 
                                                            "APLICAR DESCUENTO"
                                                        }
                                                        </Button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    : 
                                    
                                    this.state.beneficioAgregado.idCode != 0 ?
                                        <div>
                                            <div className="contProducto contProductoBotella">
                                                
                                                <div className="contInfo" style={{padding:"0px 20px 0px 10px"}}>
                                                    <div  className="nameProducto">{this.state.beneficioAgregado.title}</div>
                                                    <div className="toppings" style={{marginRight:"0px",marginBottom:"0px"}}>{this.state.beneficioAgregado.description2}</div>
                                                    
                                                    <div className='contDeleteBeneficio' onClick={(e) => this.removerBeneficio(e)}>
                                                        <i className="glyphicon glyphicon-trash"></i>
                                                    </div>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    : null
                                    
                                }

                                


                            </div>
                            
                        }
                        


                        

                        {
                            this.state.beneficioAgregado.idCode != 0 && this.state.carrito.length != 0 ?
                            <div className="contTotalDescuento">
                                <Row style={{padding:"0px 20px"}}>
                                    <Col xs={6}>
                                        SUB-TOTAL
                                    </Col>
                                    <Col xs={6}>
                                        ${parseFloat(this.state.total).toFixed(2)}
                                    </Col>
                                </Row>
                                <Row style={{padding:"0px 20px"}}>
                                    <Col xs={6}>
                                        DESCUENTO
                                    </Col>
                                    <Col xs={6}>
                                        ${parseFloat(parseFloat(this.state.total) * (parseFloat(this.state.beneficioAgregado.value) / 100)).toFixed(2)}
                                    </Col>
                                </Row>
                                <hr />
                                <Row style={{padding:"0px 20px"}}>
                                    <Col xs={6}>
                                        TOTAL 
                                    </Col>
                                    <Col xs={6}>
                                        ${parseFloat((parseFloat(this.state.total).toFixed(2)) - parseFloat(parseFloat(this.state.total) * (parseFloat(this.state.beneficioAgregado.value) / 100)).toFixed(2)).toFixed(2)}
                                    </Col>
                                </Row>
                            </div>
                        : 
                            <div className= "conTotalCarrito">
                                TOTAL <span>${parseFloat(this.state.total).toFixed(2)}</span>
                            </div>
                        }
                        

                        

                        

                        <Button style={{width:"100%",marginTop:"10px"}} onClick={(e) => this.handleClickDetalleOrden(e)} className={this.state.customer.id == 0 ? "btn-agregar btnContinuarCrearCuenta btnContinuarDesactivado" : this.state.dataHome.amount_min > (this.state.beneficioAgregado.idCode != 0 ? (this.state.total - (this.state.total * (parseFloat(this.state.beneficioAgregado.value) / 100))) : this.state.total) ? "btn-agregar btnContinuarCrearCuenta btnContinuarDesactivado" : "btn-agregar btnContinuarCrearCuenta"}>
                        {
                            this.state.loadingServicioCliente == true ?
                                <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                            : 
                            "PROCESAR PAGO"
                        }
                        </Button>

                        {
                            this.state.dataHome.amount_min != "" ? 
                            this.state.dataHome.amount_min > (this.state.beneficioAgregado.idCode != 0 ? (this.state.total - (this.state.total * (parseFloat(this.state.beneficioAgregado.value) / 100))) : this.state.total) ?
                            <div className="espacioInfoDelivery montoMinimo d-flex justify-content-center">
                            Monto mínimo de compra: {this.state.dataHome.currency}{parseFloat(this.state.dataHome.amount_min).toFixed(2)}</div> 
                            : null
                            : ""
                        }

                    </div>

                    
                    </div>
                : null
            }
           

            
           
            <Modal
                    isOpen={this.state.setIsOpenRegistro}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.setState({setIsOpenRegistro:false})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left",fontSize:"19px"}}>inicio de sesión requerido</div>
                        <Row>
                            <Col xs={6}>
                                <Button style={{width:"100%"}} onClick={() => this.setState({setIsOpenRegistro:!this.state.setIsOpenRegistro})}  className="btn-agregar btnCancelar">más tarde</Button>
                            </Col>
                            <Col xs={6}>
                                <Button style={{width:"100%"}} onClick={(e) => this.setState({setIsOpenRegistro:false,setIsOpenLogin:true})} className="btn-agregar btnContinuarCrearCuenta">
                                iniciar sesión
                                </Button>
                            </Col>
                            
                        </Row>
                      
                    </div>
                    
            </Modal>


            <Modal
                    isOpen={this.state.setIsOpenLogin}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="popupHeader">
                        <img onClick={() => this.setState({setIsOpenLogin:false})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left",fontSize:"19px"}}>inicio de sesión requerido</div>
                        

                        <div>
                            <input type="hidden" name="target" id="target" value="" />
                            <div className="input" style={{marginTop:"15px"}}>
                                <label for="header-account-sign-in-email">EMAIL</label>
                                <input 
                                type="email"
                                className={this.state.errorEmail ? "error" : ""} 
                                onChange={(text) => {
                                    this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                }}
                                value={this.state.email}
                                name="user"/>

                                {
                                    this.state.errorEmail == true ?
                                        <label className="errorLogin">{this.state.errorEmailText}.</label>
                                    :null
                                }

                            </div>
                            <div className="input">
                                <label for="header-account-sign-in-password">CONTRASEÑA
                                <a onClick={(e) => this.setState({setIsOpenPass:true,setIsOpenLogin:false}) } className="button-secondary forgot-password-link">Restablecer contraseña</a>
                                </label>
                                <input 
                                type="password" 
                                className={this.state.errorPass ? "error" : ""} 
                                onChange={(text) => {
                                    this.setState({pass:text.target.value,errorPass:false})
                                }}
                                value={this.state.pass}
                                autocomplete="off" 
                                name="pass" 
                                    />

                                {
                                    this.state.errorPass == true ?
                                        <label className="errorLogin">Campo obligatorio.</label>
                                    :null
                                }

                            </div>

                            {
                                this.state.mensajeLogin != "" ?
                                    <label className="errorLogin" style={{textAlign:"center",display:"block",paddingBottom:"0px"}}>{this.state.mensajeLogin}</label>
                                :null
                            }


                            
                            
                            <div className="input">

                                <Button onClick={(e) => this.loginUser(e)} className="btn-agregar btn btn-primary" style={{marginRight:"20px",height:"34px",marginLeft:"20px",width: 'calc(100% - 40px)',marginTop:"10px",marginBottom:"20px"}} className="btn-agregar btnConfirmar">
                                {
                                    this.state.loadLogin == true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "INGRESAR A CUENTA"
                                }
                                </Button>

                                
                            </div>
                            

                            <div className="popup-footer">
                                <p>¿No tienes cuenta?,  <a href="/crearcuenta" title="Registrate">Regístrate</a></p>
                            </div>
                        </div>
                      
                    </div>
                    
            </Modal>


            <Modal
                    isOpen={this.state.setIsOpenPass}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="popupHeader">
                        <img onClick={() => this.setState({setIsOpenPass:false})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left",fontSize:"19px"}}>restablecer contraseña</div>
                        

                        
                        <div className='nombreProductoAgregado' style={{fontSize:"14px",marginTop:"5px",marginBottom:"5px"}}>Ingresa tu dirección de email y te enviaremos instrucciones para restablecerla.</div>

                        <div>
                            <input type="hidden" name="target" id="target" value="" />
                            <div className="input" style={{marginTop:"15px"}}>
                                <label for="header-account-sign-in-email">EMAIL</label>
                                <input 
                                type="email"
                                className={this.state.errorEmailPass ? "error" : ""} 
                                onChange={(text) => {
                                    this.setState({emailPass:text.target.value,errorEmailPass:false,errorEmailPassText:""})
                                }}
                                value={this.state.emailPass}
                                name="user"/>

                                {
                                    this.state.errorEmailPass == true ?
                                        <label className="errorLogin">{this.state.errorEmailPassText}.</label>
                                    :null
                                }

                            </div>

                            {
                                this.state.errorPassSend == true ?
                                    <label className="errorLogin" style={{textAlign:"center",display:"block",paddingBottom:"0px"}}>{this.state.mensajePass}</label>
                                :null
                            }

                            {
                                this.state.successPassSend == true ?
                                    <label className="error" style={{marginBottom:"20px",color:"#8FD400",display:"block",textAlign:"center",fontSize:"12px"}}>{this.state.mensajePass}</label>
                                :null
                            }

                            
                            
                            <div className="input">

                                <Button onClick={(e) => this.passUser(e)} className="btn-agregar btn btn-primary" style={{marginRight:"20px",height:"34px",marginLeft:"20px",width: 'calc(100% - 40px)',marginTop:"10px",marginBottom:"20px"}} className="btn-agregar btnConfirmar">
                                {
                                    this.state.loadPass == true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "Enviar"
                                }
                                </Button>

                                
                            </div>

                        </div>
                      
                    </div>
                    
            </Modal>
           
            <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenValidacionTienda}
                    style={customStyles}
                    contentLabel=""
                >
                <img onClick={(e) => this.setState({setIsOpenValidacionTienda:false}) } src="/images/cross.png" className="closeModal" />
                <div className='productoAgregado'></div>
                <div className='nombreProductoAgregado' dangerouslySetInnerHTML={{__html: this.state.mensajeTiendaCerrada}}></div>
                <Button onClick={(e) => this.setState({setIsOpenValidacionTienda:false}) }  className="btn-agregar btnContinuarCrearCuenta">Aceptar</Button>

            </Modal>
           
        </div>
      );
    }
}




export default HomeMain;
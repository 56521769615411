import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Row,Col,Button} from "react-bootstrap";
import "react-activity/dist/Spinner.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Spinner } from "react-activity";
import Http from '../../libs/http'
import { HeaderBotones } from '../../header/header_botones'


class TerminosCondiciones extends React.Component {

    state = {
        loadInfo: true,
    };


    constructor(props) {
        super(props);
    }


    async componentDidMount() {


        this.setState({isMobileSend:this.props.isMobileSend})

        const basesGeneralesHTML = `
    <h1 class='tituloTerminos'>Bases Generales de Promoción Comercial</h1>

    <h2>Nombre de la Promoción</h2>
    <p>¡Llaolover! Visítanos, acumula puntos y gana un viaje a España.</p><br/><br/>

    <h2 class='tituloTerminos'>1. Objetivo</h2>
    <p class='pPadding'>llaollao quiere estar en la mente de los consumidores y ofrecer la experiencia de un viaje para dos personas a Madrid, España, con todos los gastos de viaje, tours, hospedaje y seguro de viaje incluidos. El ganador será seleccionado por rifa entre los clientes que acumulen puntos con llaollao club durante el período de la promoción.</p>
    
    <h2 class='tituloTerminos'>2. Del Patrocinador</h2>
    <ul class='ulTerminos'>
        <li>El Patrocinador: LLAOLLAO EL SALVADOR, S.A. DE C.V., con oficinas en Urbanización La Sultana, Calle Las Azucenas, Casa #17-F, Antiguo Cuscatlán, La Libertad.</li>
        <li>Marca del producto participante: llaollao El Salvador.</li>
    </ul>
    
    <h2 class='tituloTerminos'>3. Vigencia de la Promoción</h2>
    <ul class='ulTerminos'>
        <li>Fecha de inicio: 1 de febrero del 2025.</li>
        <li>Fecha última para participar: 28 de febrero del 2025.</li>
        <li>Fecha de anuncio de ganador: 7 de marzo del 2025.</li>
        <li>Fecha de finalización de entrega de premios: 30 de abril del 2025.</li>
    </ul>
    
    <h2 class='tituloTerminos'>4. Participantes</h2>
    <div class='pPadding'>
    <p>Participan todas las personas mayores de edad registradas en llaollao club que acumulen puntos a través de la aplicación LEAL durante el período de la promoción. No participan empleados de LLAOLLAO EL SALVADOR, S.A. DE C.V. ni de LEAL.</p>
    </div>
    <h2 class='tituloTerminos'>5. Forma de Participar</h2>
     <div class='pPadding'>
    <p>
    El consumidor, al realizar una compra arriba de <strong>OCHO DÓLARES DE LOS ESTADOS UNIDOS DE AMÉRICA ($8.00)</strong>, es decir, participan los consumidores que realicen compras de <strong>OCHO DÓLARES CON UN CENTAVO DE LOS ESTADOS UNIDOS DE AMÉRICA ($8.01)</strong> en adelante, de cualquiera de los productos dentro de cualquier sucursal de la marca llaollao, deberá solicitar la acumulación de los puntos del llaollao club para que se le pueda registrar su oportunidad de participar en la promoción. En el caso que el participante no sea parte del programa del llaollao club, este podrá registrarse dentro de la plataforma de puntos LEAL, descargando la aplicación, realizando el registro en la sucursal o solicitando el registro en redes sociales al momento de efectuar la compra para poder acceder a la acumulación de puntos.
</p>
<p>
    La acumulación de los puntos dentro del programa llaollao club (programa de lealtad) por medio de la aplicación LEAL es de <strong>dos puntos por cada $1.00 consumido</strong>, los cuales pueden ser verificados por cada consumidor en su cuenta personal o por todos los medios autorizados.
</p>
<p>
    El consumidor únicamente podrá obtener una oportunidad de participar por cada ticket de compra que supere <strong>OCHO DÓLARES CON UN CENTAVO DE LOS ESTADOS UNIDOS DE AMÉRICA ($8.01)</strong> en adelante.
</p>
<p>
    Se hace constar que no tomarán en cuenta las visitas, compras y los puntos que sean acumulados fuera del período de la promoción, es decir, únicamente se contarán los puntos y visitas acumulados entre el <strong>1 de febrero del 2025</strong> al <strong>28 de febrero del 2025</strong>.
</p>
<h2 class='tituloTerminos'>Premios para Otorgar en la Actividad Promocional</h2>
<p>1 viaje a Madrid, España para dos personas que incluye:</p>
<ul class='ulTerminos'>
    <li>Boleto aéreo San Salvador / Madrid / San Salvador, con impuestos incluidos en clase económica. Sujeto a confirmación según fechas de viaje. La tarifa permite 1 artículo personal + maleta de carga de 50 libras por pasajero (no incluye maleta de mano).</li>
    <li>Traslados regulares aeropuerto en Madrid / hotel / aeropuerto en Madrid.</li>
    <li>6 noches de alojamiento en Hotel Petit Palace Plaza Mayor 4 estrellas o similar.</li>
    <li>Desayunos incluidos.</li>
    <li>Tour de Ciudad, Catedral de Almudena y Palacio Real.</li>
    <li>Tour a Toledo y Segovia.</li>
    <li>Seguro de viajes con cobertura de $60,000.00 por persona.</li>
    <li>Impuestos Hoteleros.</li>
    <li>$500.00 en efectivo para gastos personales durante el viaje.</li>
</ul>
<div class='paqueteRifa'>
    El paquete antes mencionado está valorado en <strong>$5,300.00 IVA Incluido</strong>
</div>
<h2 class='tituloTerminos'>Entrega de los Premios</h2>
<p>
    El ganador se seleccionará por medio de nuestro proveedor de servicio Leal, quien brindará los datos personales de las personas que hayan registrado su participación por consumos arriba de <strong>OCHO DÓLARES DE LOS ESTADOS UNIDOS DE AMÉRICA ($8.00)</strong>, es decir, <strong>OCHO DÓLARES CON UN CENTAVO DE LOS ESTADOS UNIDOS DE AMÉRICA ($8.01)</strong> en adelante. Para ello se utilizará la aplicación <a href="https://app-sorteos.com/es" target="_blank">https://app-sorteos.com/es</a> para seleccionar al ganador del premio de forma aleatoria. Este se publicará por medio de nuestras redes sociales: Facebook e Instagram.
</p>
<p>
    Así mismo, se contactará al ganador el día <strong>7 de marzo del 2025</strong> por medio de sus contactos telefónicos y correos para informarles que el premio se entregará en una ceremonia en vivo en la sucursal llaollao Las Ramblas Santa Tecla, ubicada en Centro Comercial Las Ramblas Santa Tecla, Carretera Panamericana, Km 10, Santa Tecla, a las 11:00 a.m. el día <strong>7 de marzo del 2025</strong>.
</p>
<p>
    Se le pedirá al ganador que asista a la ceremonia para poderle entregar de manera inmediata su premio. Si en caso el ganador no pueda asistir, tendrá hasta el <strong>30 de abril del 2025</strong> para reclamar su premio y deberá presentarse en las oficinas de <strong>LLAOLLAO EL SALVADOR, S.A. DE C.V.</strong>, ubicadas en Urbanización La Sultana, Calle Las Azucenas, Casa 17F, distrito de Antiguo Cuscatlán, Municipio de La Libertad Este, Departamento de La Libertad, con su DUI.
</p>
<p>
    En caso de no confirmar asistencia o reclamar el premio en las fechas y horarios establecidos, <strong>LLAOLLAO EL SALVADOR, S.A. DE C.V.</strong> dispondrá del premio que no sea reclamado, salvo causa justificada del ganador que haga imposible reclamar su premio.
</p>
</div>
    <h2 class='tituloTerminos'>6. REGLAS PARA EL RECLAMO DE PREMIOS</h2>
<ul class='ulTerminos'>
  <li>El ganador deberá llevar su Documento Único de Identidad, ya que la dinámica es especialmente para mayores de edad.</li>
  <li>El premio se entregará al cliente ganador que haya reclamado su premio conforme a los términos estipulados en el presente Reglamento.</li>
  <li>Se hace constar que LLAOLLAO EL SALVADOR, S.A. DE C.V. no obligará al ganador a tomarse una foto con el premio obtenido a fin de hacer publicidad, salvo que se obtenga un consentimiento expreso por escrito.</li>
  <li>Se condicionará al participante a entregar el documento mediante el cual ha sido registrado para participar de acuerdo con el Art. 16 de la Ley de Protección al Consumidor.</li>
</ul>

<h2 class='tituloTerminos'>7. RESPONSABILIDAD, CONDICIONES Y RESTRICCIONES DEL PATROCINADOR</h2>
<ul class='ulTerminos'>
  <li>LLAOLLAO EL SALVADOR, S.A. DE C.V. entregará el premio acorde a las especificaciones establecidas por la agencia de viajes, por lo que no se hace responsable de cualquier demora, pérdida de equipaje, cancelación de viajes y cualquier otra condición ajena que surja posterior a la entrega de este.</li>
  <li>El premio es personal e intransferible y deberá de ser otorgado para dos personas al mismo momento, no pueden separarse los pasajes.</li>
  <li>El ganador debe canjear su premio antes que termine la fecha límite de canje, el 30 de abril del 2025. De lo contrario, pierden la posibilidad de reclamar el premio, ya que es requerida la redención del premio como mínimo con dos meses de anticipación a la fecha del viaje para verificar la disponibilidad. Se deberá enviar un correo electrónico presentando la carta premio con foto de ambos pasaportes al correo electrónico: <a href="mailto:xmelgar@avitours.com.sv">xmelgar@avitours.com.sv</a> o llamar al número de teléfono <a href="tel:+50325107639">2510-7639</a>.</li>
  <li>El itinerario de vuelo se confirma al momento de la reservación ya que, por fecha o disponibilidad, se reservará la mejor opción de vuelo para el pasajero, vuelos siempre con escala.</li>
  <li>El premio estará vigente para redención como fecha límite de viaje hasta el 31 de agosto del 2025.</li>
  <li>El premio no aplica para viajar en temporada alta como semana santa, semana agostina o feriados en el país destino (junio-julio). Si el ganador requiere viajar en temporada alta, se le cotizará la nivelación tarifaria que aplique y será pagada directamente por el ganador.</li>
  <li>Se requiere que el ganador y su acompañante tenga su pasaporte con una vigencia mínima de 6 meses a partir de la fecha del viaje y serán responsables del cumplimiento de los requisitos migratorios para la realización del viaje, incluyendo requisitos de ingresos a los países en tránsito (en caso apliquen) y del destino final acorde al itinerario de su vuelo.</li>
  <li>Al participar en la promoción, el cliente acepta todos los términos y condiciones, los cuales tendrán carácter definitivo en todos los asuntos relacionados a esta promoción. Cualquier violación a las mismas, a los procedimientos o sistemas establecidos para la realización de la promoción implica la inmediata eliminación de la participación del cliente y cancelación del premio.</li>
  <li>Como condición para participar, los participantes están de acuerdo con que las agencias de publicidad del patrocinador u organizador, y sus respectivas compañías matrices, subsidiarias y compañías afiliadas, concesionarios, proveedores de premios, publicidad y promoción, y todos sus respectivos funcionarios, directores, empleados, representantes y agentes, no tendrán ningún tipo de responsabilidad, y se eximen de cualquier responsabilidad por cualquier lesión, pérdida o daño de cualquier tipo a persona(s), incluyendo la muerte, o propiedad como resultado total o parcialmente, directo o indirectamente, de la aceptación, posesión, uso indebido o uso de un premio.</li>
  <li>La información personal requerida del ganador es la que contiene su DUI, y el uso de esta información es para exclusividad del patrocinador, para el caso, LLAOLLAO EL SALVADOR, S.A. DE C.V. Los aspectos imprevistos de esta promoción serán resueltos por la Administración de LLAOLLAO EL SALVADOR, S.A. DE C.V.</li>
  <li>Se hace constar que el llaollao club es un sistema de acumulación de puntos el cual se encuentra administrado y respaldado por el software de la empresa PUNTOS LEAL.</li>
  <li>Se hace constar que los términos y condiciones que regulan el llaollaoclub los cuales incluyen el registro, acumulación de puntos, restricciones y demás condiciones son las establecidas en la siguiente dirección: <a href="https://llaollaosv.com/terminoscondicionesllaollao" target="_blank">https://llaollaosv.com/terminoscondicionesllaollao</a></li>
</ul>

     <h2  class='tituloTerminos'>8.ENTIDAD BENEFICA</h2>
    <p class='pPadding'>
      Dado que los premios no son en efectivo, sino en producto propio de la
      sociedad o en bienes que esta adquirirá, y dada la mecánica de la
      promoción y los premios, no se incluye nombre, dirección ni teléfono de
      entidad Benéfica a quien se donarían los premios no reclamados.
    </p>
    <h2  class='tituloTerminos'>9. INFORMACIÓN O CONSULTAS SOBRE LA ACTIVIDAD PROMOCIONAL</h2>
    <p class='pPadding'>
      Los participantes podrán consultar las bases de competencia de la
      promoción comercial las cuales estarán habilitadas por medio de la página
      web <a href="http://www.llaollaosv.com">www.llaollaosv.com</a>, y/o enviar
      un correo a <a href="mailto:info@llaollaosv.com">info@llaollaosv.com</a>
      y/o en las oficinas de LLAOLLAO EL SALVADOR, S.A. DE C.V. ubicadas en
      Urbanización La Sultana, Calle Las Azucenas, Casa 17F, distrito de
      Antiguo Cuscatlán, Municipio de La Libertad Este, Departamento de La
      Libertad, para obtener cualquier tipo de información referente a la
      promoción.
    </p>
`;

     
        
        this.setState({content:basesGeneralesHTML,loadInfo:false,titulo:"Términos y condiciones"})     
        
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }
                    
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>{this.state.titulo}</h1>

                <div className="clear"></div>

                {
                    this.state.loadInfo == true ?
                        <div className="contSpinner"><Spinner /></div>
                    :

                
                    <Row dangerouslySetInnerHTML={{__html: this.state.content}} className={this.state.isMobileSend ? "altoMinimoMovil contAcercaDe noPadding":"contAcercaDe"}>
                            
                    </Row>

                }
                

                

                
            </Col>
        </Row>
      );
    }
}

export default TerminosCondiciones;